import { IoLogoWindows } from "react-icons/io";
import { GrAndroid } from "react-icons/gr";
import { FaApple } from "react-icons/fa";
import { HiClipboard } from "react-icons/hi";
import { BiErrorAlt } from "react-icons/bi";
import { CgSpinnerTwo } from "react-icons/cg";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { request, gql } from "graphql-request";
import { toast } from "react-toastify";

function App() {
  const endpoint = "https://api-sasip.apluseducation.lk/api/gql";

  const query0 = gql`
    query {
      appInfo {
        apiName
        apiVersion
        storageBucketVideoRoot
        storageBucketImageRoot
      }
    }
  `;
  const query = gql`
    query getLessonDetails($session_id: String!) {
      getLessonDetails(session_id: $session_id) {
        lesson {
          title
          _id
        }
        course {
          title
        }
        teacher {
          title
        }
        link_params
        link_exire_after
      }
    }
  `;
  const [platform, setPlatform] = useState("windows");

  const [code, setCode] = useState();
  const [sesId, setSesId] = useState();
  const [lesson, setLesson] = useState({
    title: "",
    _id: "",
    teacher: "",
    course: "",
  });
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const sessionId = searchParams.get("sessionId");
    if (!loading) {
      if (sessionId) {
        setSesId(sessionId);
        fetch(sessionId);
      } else {
        toast.error("Invalid Link!");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const fetch = async (sessionId) => {
    try {
      setLoading(true);
      const data = await request(endpoint, query, { session_id: sessionId });
      setLesson({
        title: data.getLessonDetails.lesson.title,
        _id: data.getLessonDetails.lesson._id,
        teacher: data.getLessonDetails.teacher.title,
        course: data.getLessonDetails.course.title,
      });
      setCode(data.getLessonDetails.link_params);
      toast.success("Data Fetched!");
    } catch (error) {
      setCode();
      const error_msg = error?.response?.errors[0]?.message || '"Failed to get passcode "';
      var msg = error_msg.substring(error_msg.indexOf('"') + 1, error_msg.lastIndexOf('"'));
      toast.error("ERROR : " + msg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-screen w-screen flex items-center justify-center bg-gray-200">
      <main className="container max-w-md p-4 bg-white h-96 rounded-lg shadow-xl flex flex-col items-center justify-start">
        <h1 className="text-green-700 text-center font-semibold text-4xl my-8">EClass Player</h1>

        <h4 className="text-green-500 text-center text-xl mb-2">Select Platform</h4>

        <div className="flex justify-center">
          <div className="grid grid-cols-3 bg-gray-200 rounded-md p-1">
            <div
              onClick={() => setPlatform("windows")}
              className={`flex items-center justify-center text-3xl rounded-md py-2 px-4 cursor-pointer ${
                platform === "windows" ? "bg-lime-500 text-white" : "text-gray-500"
              }`}
            >
              <IoLogoWindows c />
            </div>
            <div
              onClick={() => setPlatform("android")}
              className={`flex items-center justify-center text-3xl rounded-md py-2 px-4 cursor-pointer ${
                platform === "android" ? "bg-lime-500 text-white" : "text-gray-500"
              }`}
            >
              <GrAndroid />
            </div>
            <div
              onClick={() => setPlatform("apple")}
              className={`flex items-center justify-center text-3xl rounded-md py-2 px-4 cursor-pointer ${
                platform === "apple" ? "bg-lime-500 text-white" : "text-gray-500"
              }`}
            >
              <FaApple />
            </div>
          </div>
        </div>
        <div className="flex-grow" />
        {loading ? (
          <CgSpinnerTwo className="animate-spin text-2xl text-green-500" />
        ) : code ? (
          <>
            <h4 className="font-bold">{lesson.title}</h4>
            <p className="mb-4 text-gray-500 text-sm">
              {lesson.teacher} | {lesson.course}
            </p>
            {platform === "windows" ? (
              <div
                onClick={() => {
                  window.open("sasip-player://lessonId=" + sesId + "&url=" + code);
                }}
                className="text-white bg-green-500 px-4 py-2 transition duration-300 ease-in-out hover:bg-green-700 hover:shadow cursor-pointer rounded-md"
              >
                Open Player
              </div>
            ) : (
              <>
                <div className="flex justify-center">
                  <div
                    className="flex items-center justify-start bg-gray-100 rounded-md overflow-hidden w-64 border-green-500 border-2 transition duration-300 ease-in-out hover:text-green-500 `}
            >"
                  >
                    <div className="flex-grow text-center font-semibold text-xl text-green-500 ">{code}</div>

                    <div
                      onClick={async () => {
                        try {
                          await navigator.clipboard.writeText(code);
                          toast.success("Copied to Clipboard");
                        } catch (error) {}
                        navigator.clipboard.writeText(code);
                      }}
                      className={`flex items-center justify-center text-3xl rounded-l-md p-2 cursor-pointer text-gray-400 transition duration-300 ease-in-out hover:text-lime-500 `}
                    >
                      <HiClipboard />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <div className="w-64 bg-red-100 p-2 rounded-md flex items-start justify-start">
            <BiErrorAlt className="mr-2 w-8 text-xl text-red-500" />
            <p className="text-red-500 text-sm">Passcode Not Found</p>
          </div>
        )}
        <div className="flex-grow" />
        <p className="text-xs font-medium text-gray-500">
          Powered by{" "}
          <a href="#" target="_blank" className="font-medium text-gray-400 hover:underline cursor-pointer" rel="noreferrer">
            SmartMatrix
          </a>
        </p>
      </main>
    </div>
  );
}

export default App;
