function NotFound() {
  return (
    <div className="h-screen w-screen flex items-center justify-center overscroll-y-auto bg-gray-200">
      <main className="container max-w-md p-4 bg-white h-96 rounded-lg shadow-md flex flex-col items-center justify-start">
        <h1 className="text-green-700 text-center font-semibold text-4xl mt-8">EClass Player</h1>

        <div className="flex-grow" />
        <h2 className="text-lime-500 text-center font-semibold text-6xl">404</h2>
        <h2 className="text-lime-500 text-center font-semibold text-2xl">Route Not Found</h2>

        <div className="flex-grow" />
        <p className="text-xs font-medium text-gray-500">
          Powered by{" "}
          <a href="#" target="_blank" class="font-medium text-gray-400 hover:underline cursor-pointer" rel="noreferrer">
            SmartMatrix
          </a>
        </p>
      </main>
    </div>
  );
}

export default NotFound;
